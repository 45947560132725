import React from 'react';
// import sections
import FT from '../components/sections/FeaturesTiles.js';

const Team = () => {

  return (
    <>
        <FT />
        {/*<FT className="illustration-section-01" />*/}
    </>
  );
}

export default Team;