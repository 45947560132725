import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import Header from "../Header";

const defaultProps = {
  width: 180,
  height: 49.2
}

const propTypes = {
  width: PropTypes.float,
  height: PropTypes.float
}


const Logo = ({
  className,
    width,
    height,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        {/*<Link to="/">*/}
          <Image
            src={require('./../../../assets/images/logo_main_white.webp')}
            alt="Open"
            width={width}
            height={height} />
        {/*</Link>*/}
      </h1>
    </div>
  );
}

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;