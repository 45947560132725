import React from 'react';
// import sections
import LoadHTML from '../components/sections/LoadHTML';

const TexasPFC = () => {

    return (
        <>
            <LoadHTML />
        </>
    );
}

export default TexasPFC;