import React from 'react';

const fileLink = 'https://datafiles-invisible.s3.us-west-1.amazonaws.com/dots_texas_pfc_editable_copy.html';
class LoadHTML extends React.Component {
    render(){
        return (
            <body style={{
                margin:0,
                padding:0,
            }}>
                <iframe src={fileLink} frameBorder="0" style={{
                    height:"850px",
                    width:"1920px"
                }} ></iframe>
            </body>
        );
    }
}
export default LoadHTML;