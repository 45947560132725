import React from 'react';
import Header from '../components/layout/HeaderData';
// import Footer from '../components/layout/Footer';

const LayoutData = ({ children }) => (
    <>
        <Header navPosition="right" />
        <main className="site-content">
            {children}
        </main>
    </>
);

export default LayoutData;