import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Meet our team',
    paragraph: 'Interdisciplinary team pioneering new era of real estate investing'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content section-inner" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/michael.png')}
                      alt="Michael Saadine team founder"
                      width={128}
                      height={128}
                      style={{borderRadius:'64px'}} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Michael Saadine
                    </h4>
                  <p className="m-0 text-sm">
                    10+ years of real estate private equity and impact investing.</p><p className="m-0 text-sm">Ex-UrbanView Capital, Northwood Investors, Stanford GSB
                    </p>
                    <div className= "header-nav-right">
                      <a href="https://www.linkedin.com/in/michaelsaadine/">
                      <Image
                        src={require('./../../assets/images/linkedin-icon.svg')}
                        width={20}
                        height={20} />
                      </a>
                    </div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/vikas.png')}
                      alt="Vikas Choudhary team founder"
                      width={128}
                      height={128}
                      style={{borderRadius:'64px'}} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Vikas Choudhary
                    </h4>
                  <p className="m-0 text-sm">
                    Co-Founder & CTO of a USD500M urban logistics technology start-up.</p><p className="m-0 text-sm"> Ex-Porter.in, Stanford GSB
                    </p>
                    <p style={{margin:'28px'}}></p>
                    <div className= "">
                      <a href="https://www.linkedin.com/in/vikasch90">
                      <Image
                        src={require('./../../assets/images/linkedin-icon.svg')}
                        width={20}
                        height={20}/>
                      </a>
                    </div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/leon.png')}
                      alt="Leon Lauk team founder"
                      width={128}
                      height={128}
                      style={{borderRadius:'64px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Leon Lauk
                    </h4>
                  <p className="m-0 text-sm">
                    10+ yrs of corporate finance experience.</p><p className="m-0 text-sm"> Ex-Deutsche Bank, Stanford GSB.
                    </p>
                    <p style={{margin:'28px'}}></p>
                    <div className= "">
                      <a href="https://www.linkedin.com/in/leon-lauk/">
                      <Image
                        src={require('./../../assets/images/linkedin-icon.svg')}
                        width={20}
                        height={20}/>
                      </a>
                    </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;