import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );


  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>
            {/*
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Making the invisible visible
                  </div>
                <h3 className="mt-0 mb-12">
                  Uncover high-potential overlooked places
                  </h3>
                  
                  <p className="m-0">
                  Insights into neighborhoods overlooked by traditional investors due to biases and lack of comparables.
                  </p> 
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/invisible-uncover.jpeg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div> */}
            <div className="split-item">
              <div className="split-item-content left-content-mobile reveal-from-left text-color-low" data-reveal-container=".split-item">
                <div className="text-s text-color-high fw-600 tt-u mb-8">
                  Making the invisible visible
                </div>
                <ul>
                  <li><span className="text-color-high">Going beyond comps:</span> Uncovering high-potential overlooked places</li>
                  <li><span className="text-color-high">Powered by deep learning:</span> Unlocking and replicating a differentiated opportunity set</li>
                  <li><span className="text-color-high">Cross-functional expertise:</span> Built by a team of experienced tech, finance, and real estate professionals</li>
                </ul>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/map-oz-dark-muted.webp')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>
{/* 
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Powered by Deep Learning
                  </div>
                <h3 className="mt-0 mb-12">
                  Go beyond comps and demographic metrics
                  </h3>
                  <p className="m-0">
                  Harnessing data and artificial intelligence to unlock and replicate a differentiated opportunity set
                  </p> 
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/map-oz-muted.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>
*/}
            {/*
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Cross-functional expertise
                  </div>
                <h3 className="mt-0 mb-12">
                  Built by a team of experienced tech, finance, and real estate professionals. 
                  </h3>
                <p className="m-0">
                  
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;